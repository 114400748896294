import React from 'react'
import Header from '../components/Header/Header'
import Article from '../components/Section_Article/Article'
import Article_card from '../components/Section_Article/Article_card'

export default function Article_page() {
  return (
    <div>
    <Article_card/>
    </div>
  )
}
